@use "../../config/variables.scss" as vars;

.header {
  background-color: #b3c1ff60;
  min-height: 90px;
  padding: 10px;
  border-radius: 5px;
  display: grid;
  align-items: center;
}

.headerBackBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-right: 2px solid grey;
  cursor: pointer;
}

.headerHeadBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 45px;
}

.headerTxt1 {
  font-family: vars.$SemiBold;
  font-size: 22px;
  color: #000;
  // padding-left: 10px;
}

.headerTxt2 {
  font-family: vars.$Medium;
  font-size: 14px;
  color: #000;
}

.headerButton {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 25px;
  border: 2px solid vars.$primary;
  font-family: vars.$SemiBold;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: vars.$primary1;
  }
}
