* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  -webkit-font-smoothing: antialiased;
  list-style: none;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

