@use "../../config/variables.scss" as vars;

.cat-card {
  background-color: #f2f2f2;
  cursor: pointer;
  border: 1px solid #eaeaea;
  &:hover {
    background-color: #eaeaea;
  }
}

.txt1 {
  color: #000;
  font-family: vars.$SemiBold;
  &:hover {
  }
}
