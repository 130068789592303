@use "./config/variables.scss" as vars;

.formLabel {
  font-family: vars.$Medium;
  font-size: 14px;
  margin-bottom: 2px;
  margin-left: 2px;
  color: grey;
  text-transform: capitalize;
}
.table-Action {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-Txt {
  // font-family: vars.$SemiBold;
  font-size: 12px;
}
.table-item {
  display: flex;
}

.screen-box {
  background-color: rgb(255, 255, 255);
}

.main-box {
  padding: 15px;
  background-color: #efefef;
  height: 90vh;
  overflow: scroll;
  @media screen and (max-width: 567px) {
    padding: 0px 10px;
  }
}

.main-txt {
  font-family: vars.$SemiBold;
  font-size: 24;
}

.card {
  background-color: #f2f2f2;
  cursor: pointer;
  color: #000;
  font-family: vars.$SemiBold;
}

.paginationBox {
  display: flex;
  justify-content: flex-end;
}
