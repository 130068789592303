@use "../../config/variables.scss" as vars;

.login-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow: hidden;

}


.login-image {
  width: 100%;
  text-align: center;
}

.login-image img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.loginScreen {
  width: 800px; 
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.loginScreen-Box3 {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 180px;
    height: 100px;
    object-fit: contain;
  }
}

.loginScreen-txt1 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: vars.$Bold;
  color: vars.$primary;
}

// Media query for smaller screens
@media only screen and (max-width: 768px) {
  .login-main {
    flex-direction: column;
    padding: 10px;
  }

  .login-image,
  .loginScreen {
    width: 100%;
  }

  .loginScreen {
    height: auto;
    padding-top: 100px;
  }

  .loginScreen-Box3 img {
    width: 120px;
    height: 70px;
  }
}

// Media query for even smaller screens
@media only screen and (max-width: 480px) {
  .login-image img {
    width: 80%;
  }
}
