
  
  .quiz-card {
    width: 100%; 
    margin: 10px; 
    height: 250px;
    
  }
  
  .qstn {
    height: 100%; 
  }
  

.quizData{
    width: 100%;
    height: 100%;
}
.txt1{
    display: flex;
}
.quizupdatebutton{
    margin-left: 80%;
}

.quiz-card{
  height: auto;
}

.scrollable-container {
    height: 550px;
    overflow-y: auto;
    
    width: 100%;

  }
  