@use "../../config//variables.scss";

.certificate-container {
    width: 100%;
    padding: 20px;
    border: 2px solid #000;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    background: #fff;
    position: relative;
  }
  
  .certificate-header {
    margin-bottom: 20px;
  }
  
  .certificate-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .certificate-content p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .certificate-content h2 {
    font-size: 22px;
    margin: 20px 0;
    color: #007bff;
  }
  
  .certificate-footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #888;
  }


  .module-card{
    cursor: pointer;
    &.locked {
      cursor: not-allowed;
      opacity: 0.5;

      
  }
  }





  // #certificate-content {
  //   display: none;
  // }
  






  #certificate-content {
    width: 100%;
    height: 100%;
    font-family: 'Arial, sans-serif';
    text-align: center;
    padding: 20px;
    display: none;
  }
  
  .certificate-container {
    border: 10px solid #000;
    padding: 20px;
  }
  
  .certificate-header {
    margin-bottom: 20px;
  }
  
  .certificate-title {
    font-size: 36px;
    font-weight: bold;
  }
  
  .certificate-content {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .certificate-footer {
    font-size: 18px;
    margin-top: 20px;
  }

  .small-logos{
    width: 50px;
  }
  
  